.button {
    display: inline-block;
    padding: 5px 25px 5px;
    background-color: #1ed760;
    border-radius: 100px;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .0625rem;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    vertical-align: middle;
    transition: background-color .2s, color .2s;
}

.button:hover {
    background-color: #2ebd59;
}

.button.small {
    padding: 3px 15px 3px;
    font-size: 10px;
}

.button.medium {
    padding: 5px 25px 5px;
    font-size: 16px;
}

.button.large {
    padding: 11px 35px 9px;
    font-size: 18px;
}

.button.disabled {
    background-color: #bbb;
    cursor: default;
}

@media only screen and (max-width: 1040px) {
    .button.small {
        padding: 1vw 5vw;
        font-size: 3vw;
    }

    .button.medium {
        padding: 1.5vw 7vw;
        font-size: 4vw;
    }

    .button.large {
        padding: 2vw 8vw;
        font-size: 5vw;
    }
}