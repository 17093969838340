@font-face {
    font-family: spotify-circular;
    src: url(../../static/media/CircularSpUIv3T-Light.fa847326.woff2) format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: spotify-circular;
    src: url(../../static/media/CircularSpUIv3T-Book.6ff898ba.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: spotify-circular;
    src: url(../../static/media/CircularSpUIv3T-Bold.c147cc23.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Handlee';
    src: url(../../static/media/Handlee.9c798c3c.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
    margin: 0;
    padding: 0;
    font-family: spotify-circular, Helvetica Neue, Helvetica, Arial, sans-serif;
    background: rgba(65, 45, 78, 1);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(65, 45, 78, 1)), color-stop(100%, rgba(0, 0, 0, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-linear-gradient(315deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(135deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#412d4e', endColorstr='#000000', GradientType=1);
    color: white;
    background-attachment: fixed;
    -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 1040px) {
    body {
        background: #30213a;
    }
}
.container {
    min-width: 1000px;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.highlight-color {
    color: #1cd156;
}

.mainContainer {
    font-size: 35px;
}

.mobileDialogTitle {
    font-size: 30pt;
    font-weight: 600;
    text-align: center;
}

.mobileDialogContent {
    font-size: 20pt;
    font-weight: 200;
    text-align: center;
    margin: 20px 0;
}

.mobileDialogButton {
    text-align: center;
    margin-bottom: 10px;
}

/* Styles for: Projects.jsx */
#helloMsg {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-size: 70pt;
}

#clarification {
    font-family: 'Handlee', cursive;
    text-align: right;
    font-size: 15pt;
}

#me {
    display: block;
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin: 30px auto;
}

#motivation {
    text-align: center;
    color: #ccc;
    max-width: 1000px;
    margin: 0 auto;
}

#welcomeText {
    text-align: center;
    margin: 100px 0;
    font-size: 40pt;
}

#projectsInitDialog {
    font-size: 16pt;
}

#projectsInitDialog #title {
    text-align: center;
    font-size: 20pt;
    margin-bottom: 20px;
}

#projectsInitDialog #findOut {
    text-align: center;
    margin-top: 20px;
}

#projects {
    text-align: center;
}

#projects .section {
    margin-bottom: 60px;
}

#projects .title {
    font-size: 60pt;
    color: #1ed760;
}

#projects .prjImageFrame {
    position: relative;
    display: inline-block;
    width: 200px;
    margin: 20px;
}

#projects .prjImage {
    width: 100%;
    border-radius: 5px;
}

#projects .prjTitle {
    position: absolute;
    font-size: 14pt;
    text-align: center;
    width: 100%;
    font-weight: 200;
}

#projects .prjOverlay {
    position: absolute;
    font-size: 14pt;
    text-align: center;
    width: calc(100% - 2px);
    height: calc(100% - 11px);
    border-radius: 5px;
    top: 0;
    opacity: 0;
    background: rgba(0, 0, 0, .5);
    -webkit-transition: opacity .2s, box-shadow .2s ease-in, border .2s;
    transition: opacity .2s, box-shadow .2s ease-in, border .2s;
    cursor: pointer;
    border: 1px solid #000;
}

#projects .prjOverlay:hover {
    opacity: 1;
    box-shadow: 0 0 30px 2px rgba(0, 0, 0, .5);
    border: 1px solid #fff;
}

#projects .infoBtn {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-shadow: 0 0 10px black;
    font-weight: 200;
}

#projectDialog {
    min-width: 700px;
}

#projectDialog .projectDialogImage {
    display: inline-block;
    width: 300px;
    margin: 0 auto;
    border-radius: 5px;
}

#projectDialog .projectDialogContent {
    display: inline-block;
    width: calc(100% - 340px);
    margin: 0 20px;
    vertical-align: top;
}

#projectDialog .projectDialogTitle {
    font-size: 30pt;
}

#projectDialog .projectDialogDescription {
    font-size: 13pt;
    font-weight: 200;
}

#projectDialog #close {
    text-align: right;
    margin-top: 20px;
}

#whatIsNext {
    background: #1ed760;
    width: calc(100% + 40px);
    margin: 0 0 -20px -20px;
}

#whatIsNext .title {
    font-size: 60pt;
    color: black;
    text-align: center;
}

#whatIsNext .onlyYouDecide {
    text-shadow: none;
    font-family: 'Handlee', cursive;
    color: black;
    margin-top: -20px;
    text-align: center;
}

#whatIsNext .content {
    text-shadow: 0 0 1px rgba(0, 0, 0, .5);
    padding: 20px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

#whatIsNext a {
    color: white;
}

#whatIsNext .codeOfThis {
    position: absolute;
    font-family: 'Handlee', cursive;
    margin: 5px 0 0 10px;
    font-size: 15pt;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
}

#whatIsNext .call {
    font-family: 'Handlee', cursive;
    color: black;
    text-align: center;
    font-weight: bold;
}

@media only screen and (max-width: 1040px) {
    .container {
        min-width: unset;
        padding: 2vw;
    }


    /* Common */
    .continueButtonContainer {
        text-align: center;
        margin-bottom: unset;
    }

    .continueButton {
        margin: 1vw;
        display: block;
    }

    .errorMessage {
        font-size: 6vw;
    }

    .errorMessage p {
        margin: 6vw 0;
    }


    /* Styles for: Projects.jsx */
    #helloMsg {
        font-size: 13vw;
        text-align: center;
    }

    #clarification {
        text-align: center;
        font-size: 4vw;
    }

    #me {
        width: 80vw;
        height: 80vw;
        margin: 30px auto;
    }

    #motivation {
        text-align: center;
        color: #ccc;
        font-size: 5vw;
    }

    #welcomeText {
        text-align: center;
        margin: 15vw 0;
        font-size: 7vw;
    }

    #projectsInitDialog {
        font-size: 4vw;
    }

    #projectsInitDialog #title {
        font-size: 6vw;
        margin-bottom: 4vw;
    }

    #projectsInitDialog #findOut {
        margin-top: 4vw;
    }

    #projects .section {
        margin-bottom: 10vw;
    }

    #projects .title {
        font-size: 15vw;
    }

    #projects .prjImageFrame {
        width: 30vw;
        margin: 3vw;
    }

    #projects .prjTitle {
        position: absolute;
        font-size: 3vw;
    }

    #projects .prjOverlay {
        font-size: 3vw;
    }

    #projects .infoBtn {
        text-shadow: 0 0 2vw black;
    }

    #projectDialog {
        min-width: unset;
    }

    #projectDialog .projectDialogImage {
        display: block;
        width: 40vw;
        margin: 0 auto;
        border-radius: 5px;
    }

    #projectDialog .projectDialogContent {
        display: block;
        width: unset;
        margin: 0 2vw;
    }

    #projectDialog .projectDialogTitle {
        text-align: center;
        font-size: 7vw;
    }

    #projectDialog .projectDialogDescription {
        font-size: 4vw;
    }

    #projectDialog #close {
        text-align: center;
        margin-top: 4vw;
    }

    #whatIsNext {
        background: #1ed760;
        width: calc(100% + 4vw);
        margin: 0 0 -2vw -2vw;
    }

    #whatIsNext .title {
        font-size: 15vw;
    }

    #whatIsNext .onlyYouDecide {
        font-size: 7vw;
        margin-top: -2vw;
    }

    #whatIsNext .content {
        padding: 2vw 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        font-size: 7vw;
    }

    #whatIsNext .codeOfThis {
        display: none;
    }

    #whatIsNext .call {
        padding-top: 2vw;
        font-size: 7vw;
    }
}
.button_button__1JGzV {
    display: inline-block;
    padding: 5px 25px 5px;
    background-color: #1ed760;
    border-radius: 100px;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .0625rem;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    vertical-align: middle;
    -webkit-transition: background-color .2s, color .2s;
    transition: background-color .2s, color .2s;
}

.button_button__1JGzV:hover {
    background-color: #2ebd59;
}

.button_button__1JGzV.button_small__I8YtZ {
    padding: 3px 15px 3px;
    font-size: 10px;
}

.button_button__1JGzV.button_medium__3AaB- {
    padding: 5px 25px 5px;
    font-size: 16px;
}

.button_button__1JGzV.button_large__v7oNO {
    padding: 11px 35px 9px;
    font-size: 18px;
}

.button_button__1JGzV.button_disabled__1Fhq- {
    background-color: #bbb;
    cursor: default;
}

@media only screen and (max-width: 1040px) {
    .button_button__1JGzV.button_small__I8YtZ {
        padding: 1vw 5vw;
        font-size: 3vw;
    }

    .button_button__1JGzV.button_medium__3AaB- {
        padding: 1.5vw 7vw;
        font-size: 4vw;
    }

    .button_button__1JGzV.button_large__v7oNO {
        padding: 2vw 8vw;
        font-size: 5vw;
    }
}
