@font-face {
    font-family: spotify-circular;
    src: url(../fonts/CircularSpUIv3T-Light.woff2) format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: spotify-circular;
    src: url(../fonts/CircularSpUIv3T-Book.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: spotify-circular;
    src: url(../fonts/CircularSpUIv3T-Bold.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Handlee';
    src: url(../fonts/Handlee.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
    margin: 0;
    padding: 0;
    font-family: spotify-circular, Helvetica Neue, Helvetica, Arial, sans-serif;
    background: rgba(65, 45, 78, 1);
    background: -moz-linear-gradient(-45deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(65, 45, 78, 1)), color-stop(100%, rgba(0, 0, 0, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(135deg, rgba(65, 45, 78, 1) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#412d4e', endColorstr='#000000', GradientType=1);
    color: white;
    background-attachment: fixed;
    -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 1040px) {
    body {
        background: #30213a;
    }
}