.container {
    min-width: 1000px;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.highlight-color {
    color: #1cd156;
}

.mainContainer {
    font-size: 35px;
}

.mobileDialogTitle {
    font-size: 30pt;
    font-weight: 600;
    text-align: center;
}

.mobileDialogContent {
    font-size: 20pt;
    font-weight: 200;
    text-align: center;
    margin: 20px 0;
}

.mobileDialogButton {
    text-align: center;
    margin-bottom: 10px;
}

/* Styles for: Projects.jsx */
#helloMsg {
    display: block;
    width: fit-content;
    margin: 0 auto;
    font-size: 70pt;
}

#clarification {
    font-family: 'Handlee', cursive;
    text-align: right;
    font-size: 15pt;
}

#me {
    display: block;
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin: 30px auto;
}

#motivation {
    text-align: center;
    color: #ccc;
    max-width: 1000px;
    margin: 0 auto;
}

#welcomeText {
    text-align: center;
    margin: 100px 0;
    font-size: 40pt;
}

#projectsInitDialog {
    font-size: 16pt;
}

#projectsInitDialog #title {
    text-align: center;
    font-size: 20pt;
    margin-bottom: 20px;
}

#projectsInitDialog #findOut {
    text-align: center;
    margin-top: 20px;
}

#projects {
    text-align: center;
}

#projects .section {
    margin-bottom: 60px;
}

#projects .title {
    font-size: 60pt;
    color: #1ed760;
}

#projects .prjImageFrame {
    position: relative;
    display: inline-block;
    width: 200px;
    margin: 20px;
}

#projects .prjImage {
    width: 100%;
    border-radius: 5px;
}

#projects .prjTitle {
    position: absolute;
    font-size: 14pt;
    text-align: center;
    width: 100%;
    font-weight: 200;
}

#projects .prjOverlay {
    position: absolute;
    font-size: 14pt;
    text-align: center;
    width: calc(100% - 2px);
    height: calc(100% - 11px);
    border-radius: 5px;
    top: 0;
    opacity: 0;
    background: rgba(0, 0, 0, .5);
    transition: opacity .2s, box-shadow .2s ease-in, border .2s;
    cursor: pointer;
    border: 1px solid #000;
}

#projects .prjOverlay:hover {
    opacity: 1;
    box-shadow: 0 0 30px 2px rgba(0, 0, 0, .5);
    border: 1px solid #fff;
}

#projects .infoBtn {
    position: absolute;
    width: fit-content;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0 0 10px black;
    font-weight: 200;
}

#projectDialog {
    min-width: 700px;
}

#projectDialog .projectDialogImage {
    display: inline-block;
    width: 300px;
    margin: 0 auto;
    border-radius: 5px;
}

#projectDialog .projectDialogContent {
    display: inline-block;
    width: calc(100% - 340px);
    margin: 0 20px;
    vertical-align: top;
}

#projectDialog .projectDialogTitle {
    font-size: 30pt;
}

#projectDialog .projectDialogDescription {
    font-size: 13pt;
    font-weight: 200;
}

#projectDialog #close {
    text-align: right;
    margin-top: 20px;
}

#whatIsNext {
    background: #1ed760;
    width: calc(100% + 40px);
    margin: 0 0 -20px -20px;
}

#whatIsNext .title {
    font-size: 60pt;
    color: black;
    text-align: center;
}

#whatIsNext .onlyYouDecide {
    text-shadow: none;
    font-family: 'Handlee', cursive;
    color: black;
    margin-top: -20px;
    text-align: center;
}

#whatIsNext .content {
    text-shadow: 0 0 1px rgba(0, 0, 0, .5);
    padding: 20px 0;
    width: fit-content;
    margin: 0 auto;
}

#whatIsNext a {
    color: white;
}

#whatIsNext .codeOfThis {
    position: absolute;
    font-family: 'Handlee', cursive;
    margin: 5px 0 0 10px;
    font-size: 15pt;
    transform: rotate(-5deg);
}

#whatIsNext .call {
    font-family: 'Handlee', cursive;
    color: black;
    text-align: center;
    font-weight: bold;
}

@media only screen and (max-width: 1040px) {
    .container {
        min-width: unset;
        padding: 2vw;
    }


    /* Common */
    .continueButtonContainer {
        text-align: center;
        margin-bottom: unset;
    }

    .continueButton {
        margin: 1vw;
        display: block;
    }

    .errorMessage {
        font-size: 6vw;
    }

    .errorMessage p {
        margin: 6vw 0;
    }


    /* Styles for: Projects.jsx */
    #helloMsg {
        font-size: 13vw;
        text-align: center;
    }

    #clarification {
        text-align: center;
        font-size: 4vw;
    }

    #me {
        width: 80vw;
        height: 80vw;
        margin: 30px auto;
    }

    #motivation {
        text-align: center;
        color: #ccc;
        font-size: 5vw;
    }

    #welcomeText {
        text-align: center;
        margin: 15vw 0;
        font-size: 7vw;
    }

    #projectsInitDialog {
        font-size: 4vw;
    }

    #projectsInitDialog #title {
        font-size: 6vw;
        margin-bottom: 4vw;
    }

    #projectsInitDialog #findOut {
        margin-top: 4vw;
    }

    #projects .section {
        margin-bottom: 10vw;
    }

    #projects .title {
        font-size: 15vw;
    }

    #projects .prjImageFrame {
        width: 30vw;
        margin: 3vw;
    }

    #projects .prjTitle {
        position: absolute;
        font-size: 3vw;
    }

    #projects .prjOverlay {
        font-size: 3vw;
    }

    #projects .infoBtn {
        text-shadow: 0 0 2vw black;
    }

    #projectDialog {
        min-width: unset;
    }

    #projectDialog .projectDialogImage {
        display: block;
        width: 40vw;
        margin: 0 auto;
        border-radius: 5px;
    }

    #projectDialog .projectDialogContent {
        display: block;
        width: unset;
        margin: 0 2vw;
    }

    #projectDialog .projectDialogTitle {
        text-align: center;
        font-size: 7vw;
    }

    #projectDialog .projectDialogDescription {
        font-size: 4vw;
    }

    #projectDialog #close {
        text-align: center;
        margin-top: 4vw;
    }

    #whatIsNext {
        background: #1ed760;
        width: calc(100% + 4vw);
        margin: 0 0 -2vw -2vw;
    }

    #whatIsNext .title {
        font-size: 15vw;
    }

    #whatIsNext .onlyYouDecide {
        font-size: 7vw;
        margin-top: -2vw;
    }

    #whatIsNext .content {
        padding: 2vw 0;
        width: fit-content;
        margin: 0 auto;
        font-size: 7vw;
    }

    #whatIsNext .codeOfThis {
        display: none;
    }

    #whatIsNext .call {
        padding-top: 2vw;
        font-size: 7vw;
    }
}